.blc_card {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 2px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 0.8);
  color: #000 !important;
}

.blc_button {
  font-size: 0.75rem !important;
}

.blc_nav_active {
  border-bottom: 3px solid #0D0C0A;
  background: transparent !important;
  color: #0D0C0A !important;
  border-radius: 0px !important;
}

.blc_event_footer {

  background: #80808014;

}

.blc_event_blc {

  background: transparent;

  padding-left: 4px;

  padding-right: 4px;

  padding-top: 8px;

  padding-bottom: 8px;

}

.fa-1x {
  font-size: 1rem !important;
}


/*!
 * Vista Custom Styles
 */
.bl_H1_Color {
  color: #070B31 !important;
}

.vista_H2_Color {
  color: #333333;
}

.vista_link_Color {
  color: #47ABE1;
}

.vista_no_padding_right {
  padding-right: 0 !important;
}

/* --login page styles-- */
.vista_login_bg {
  background: url('../assets/img/BG3.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  /* backdrop-filter: blur(5px); */
}

.vista_login_card {
  background-color: #0405068a !important;
}


.vista_disabled {
  opacity: 0.4;
}

.vista_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blc_card_head {
  color: #565555;
  font-weight: bold;
}

.bl-font-size-12px {
  font-size: 12px !important;
}

/* --collapsing styles and logic-- */
@media (min-width: 768px) {
  .vistaContentMargin {
    margin-top: 55px;
    margin-left: 224px;
  }

  .vistaCollapsedContentMargin {
    margin-left: 64px !important;
  }

  .vistaHeaderMargin {
    left: 219px;
  }

  .vistaCollapsedHeaderMargin {
    left: 64px !important;
  }

}

@media (max-width: 768px) {
  .vistaContentMargin {
    margin-top: 55px;
    margin-left: 0px;
  }

  .vistaHeaderMargin {
    left: 100px;
  }

  .vistaCollapsedHeaderMargin {
    left: 0px !important;
  }

}

/*  ---- thumbnail checkboxes-------- */
.vista_thumb_container {
  position: relative;
}

.vista_thumb_checkbox {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  cursor: pointer;
}

.vista_thumb_height {
  height: 50px !important;
  opacity: 0.6;
}

.vista_thumb_height:hover {
  filter: invert(1);
  cursor: pointer;
}

.vista_thumb_active {
  filter: invert(1);
}

/*  ---- nav icons-------- */
.vista_nav_icon {
  height: 15px !important;
  filter: contrast(0.3);
  margin-right: 5px;
}

.vista_nav_icon_active {
  filter: invert(1);
}

/*  ---- timeline styles-------- */
ul.timeline {
  list-style-type: none;
  position: relative;
  font-size: 12px;
  margin-left: -18px;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline>li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline>li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #E94359;
  left: 22px;
  width: 15px;
  height: 15px;
  z-index: 400;
}

.timelineMainText {
  font-weight: 500;
}

/*  ---- homepage card styles-------- */
.vistaHomePageCard {
  border: 0px !important;
}

.vistaHomePageCardHeader {
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 3px solid #47ABE1;
}

.vistaHomePageCardBody {
  background: #f9f9f9;
  padding-left: 0px;
  padding-right: 0px;
  color: #070B31;
}

/* mark favourite compoenet */

/* .star-rating {
  border:solid 1px #ccc;
  display:flex;
  flex-direction: row-reverse;
  font-size:1.5em;
  justify-content:space-around;
  padding:0 .2em;
  text-align:center;
  width:5em;
} */

.star-rating {
  font-size: 1.7em;
  margin-top: -5px;
}

.star-rating input {
  display: none;
}

.star-rating label {
  color: #ccc;
  cursor: pointer;
}

.star-rating :checked~label {
  color: #f90;
}

.star-rating label:hover,
.star-rating label:hover~label {
  color: #fc0;
}

/* ---------------- sliding panel ---------------------------------- */

.modal-dialog.modal-dialog-slideout {
  box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-dialog-slideout .modal-content {
  border: 0;
}

.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 69px;
  display: block;
}

.modal-dialog-slideout .modal-header h5 {
  float: left;
}

/* --------------------------- guest page ------------------------------------ */


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

/* rtl:end:ignore */


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0;
  /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -.05rem;
}

/* rtl:end:remove */

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.vistaMarkImage {
  background-color: transparent;
  border-radius: 0;
  border: 0px;
}

.bl-social-links a {
  font-size: 1rem;
  display: inline-block;
  color: var(--black-40, rgba(28, 28, 28, 0.40)) !important;
  line-height: 0;
  margin-right: 1rem;
  transition: 0.3s;
  cursor: pointer;
}

.bl-accordion-icon {
  float: right;
  border: 1px solid;
  padding: 0 0.25rem;
  border-radius: 2px;
  cursor: pointer;
}

.bl-accordion-icon .collapsed {
  transform: rotate(270deg);
}

.bl-company-profile img {
  height: 3.625rem;
}

.bl-individual-section div {
  display: flex;
  gap: 1rem;
  place-items: center;
  padding: 0.8rem;
}

.bl-user-permission img {
  height: 2rem;
  width: 2rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.bl-activity-item {
  gap: 0.5rem;
  position: relative;
}

.bl-activity-badge {
  height: 1rem;
  width: 1rem;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 0 0 5px white;
  background: center/contain no-repeat url('https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png');
}

.bl-activity-item::before {
  content: "";
  height: 90%;
  width: 1px;
  background-color: #1C1C1C1A;
  position: absolute;
  transform: translateX(0.4rem);
  bottom: 0;
}

.bl-activity-item:last-child::before {
  height: 0;
}

.bl-filter-clear {
  color: #E94359 !important;
}

.btn-primary-login {
  background-color: #8b8b8ca8;
  border-color: #8b8b8ca8;
  color: #fff;
}

.btn-primary-login:hover {
  background: #0e0f10;
}

.bl-overview-img {
  height: 4.5rem;
}

.bl-report-thumbnail {
  height: 2rem;
  flex-shrink: 0;
}

.bl-chip {
  border-radius: 3.75rem;
  display: inline-flex;
  padding: 0.3125rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  color: #232323;
  margin-right: 0.25rem;
  border: 1px solid #232323;
  font-weight: 500;
}

.bl-filter-chip {
  display: flex;
  padding: 0.4rem 1.0625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #F1F1F1;
  color: #232323;
  margin-right: 0.25rem;
  font-weight: 600 !important;
}

.bl-filter-chip.active {
  background: #232323;
  color: #FFFFFF;
}

.bl-activity {
  display: flex;
  padding: var(--spacing-4, 0.25rem);
  align-items: flex-start;
  gap: var(--spacing-8, 0.5rem);
  align-self: stretch;
  font-size: 12px;
}

.bl-user-activity-icon {
  padding: 0.25rem;
  background: #b7d9f8;
  height: 1.2rem;
  width: 1.2rem;
}

.bl-w-17rem {
  width: 17rem !important;
}

.bl-ml-17rem {
  margin-left: 17rem !important;
}

.bl-grid-img {
  height: 9.375rem;
  object-fit: cover;
}

.bl-catalog-category-view table tbody tr:last-child td {
  border-bottom: none;
}

.bl-report-count {
  /* float: right; */
  background: #fff;
  color: #232323;
  padding: 0 0.2rem;
  border-radius: 4px;
  font-size: 10px !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

figure pre {
  text-wrap: inherit;
}

table.bl-company-overview-risk {
  border-collapse: inherit;
  border-spacing: 15px;
}

table.bl-company-overview-risk tr td {
  border: 1px solid #e3e6f0;
  border-radius: 6px;
}


/* SWITCH BUTTON */

.bl-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.bl-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.bl-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.bl-slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 5px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.bl-slider {
  background-color: #2196F3;
}

input:focus+.bl-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.bl-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.bl-slider.round {
  border-radius: 34px;
}

.bl-slider.round:before {
  border-radius: 50%;
}

.bl-card-deck {
  margin: 0;
  gap: 1%;
}

.bl-ai-card-deck {
  margin: 0;
  gap: 1%;
}

.bl-ai-card-deck .card {
  min-width: 49%;
  max-width: 50%;
  margin: 0;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .bl-card-deck .card {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .bl-card-deck .card {
    min-width: 49%;
    max-width: 50%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .bl-app-card {
    min-width: 23%;
    max-width: 23%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .bl-recommended {
    max-width: 31%;
    min-width: 31%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }

  .bl-ai-model-card-deck {
    max-width: 48%;
    min-width: 48%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }
}

@media (min-width: 992px) {
  .bl-card-deck .card {
    min-width: 32%;
    max-width: 32%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .bl-app-card {
    min-width: 15%;
    max-width: 15%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .bl-recommended {
    max-width: 23%;
    min-width: 23%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }

  .bl-ai-model-card-deck {
    max-width: 32%;
    min-width: 32%;
    margin-left: 0.6% !important;
    margin-right: 0.6% !important;
  }
}

@media (min-width: 1200px) {
  .bl-card-deck .card {
    min-width: 24%;
    max-width: 24%;
    margin: 0;
    margin-bottom: 1.5rem;
  }
}

.bl-rounded-info {
  background: #67b7dc;
  border-radius: 50%;
  color: #fff;
  padding: 5px;
}

.bl-w-15per {
  width: 15%;
}

.bl-w-5rem {
  width: 5rem;
}

.bl-w-4rem {
  width: 4rem;
}

.bl-desc-truncate-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bl-truncate-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bl-desc-truncate-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bl-truncate-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bl-truncate-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bl-truncate-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.bl-h-2 {
  height: 2.5rem;
}

.bl-h-1-4rem {
  height: 1.4rem;
}

.bl-minh-10rem {
  min-height: 10rem;
}

.d-grid {
  display: grid;
}

.w-40 {
  width: 40% !important;
}

.bl-text-due-deligence {
  color: #0B84A5;
}

.bl-text-negotiation {
  color: #B47E04;
}

.bl-text-closed {
  color: #6F4E7C;
}

.bl-modal-context {
  background: #ffffffbf;
  backdrop-filter: blur(6px);
}

.bl-min-vh-95 {
  min-height: 95vh;
}

.bl-min-vh-94 {
  height: 88vh;
}

.bl-modal-action {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  width: auto;
}

.bl-search-footer {
  position: sticky;
  bottom: 20px;
  left: 10px;
  right: 10px;
  border-radius: 10px;
}

.bl-rotate-175 {
  transform: rotate(175deg);
}

.bl-modal-action {
  z-index: 9999;
}

.bl-modal-content {
  position: absolute;
  width: 100%;
  bottom: 3rem;
}

.active-menu img,
.dropdown-item img {
  width: 1.1rem;
}

.w-1-1rem {
  width: 1.1rem;
}

.bl-h-1-25rem {
  height: 1.25rem;
}

.bl-h-2rem {
  height: 2rem;
}

.bl-border-bottom-grad {
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #d9d9d900, #d9d9d9, #d9d9d900) 1;
}

.bl-border-right-grad {
  border-right: 1px solid;
  border-image: linear-gradient(180deg, #d9d9d900, #d9d9d9) 1;
}

.bl-border-right-grad-2 {
  border-right: 1px solid;
  border-image: linear-gradient(180deg, #d9d9d9, #d9d9d900) 1;
}

.bl-w-3rem {
  width: 3rem;
}

.bl-ai-sidebar {
  width: 19rem !important;
  max-height: 95.3vh;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;
  overflow: auto;
}

.bl-min-vh-98 {
  min-height: 98vh;
}

.bl-vh-98 {
  height: 98vh;
}

.bl-vh-95 {
  height: 95vh;
}

.bl-card-visibility {
  transform: translateZ(0)
}

.bl-minimize-modal {
  width: 0 !important;
  height: 0 !important;
}

.bl-minimize-content {
  min-height: 2rem !important;
  position: fixed;
  width: 15rem;
  bottom: 0;
  right: 20px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.bl-mw-30rem {
  max-width: 30rem;
}

.bl-mt-10rem {
  margin-top: 10rem;
}

.bl-mb-20rem {
  margin-bottom: 20rem;
}

.bl-mb-15rem {
  margin-bottom: 15rem;
}

.bl-mt-20 {
  margin-top: 20px;
}

.bl-pt-20 {
  padding-top: 20px;
}

.bl-assistant-sec {
  border-bottom: 1px solid var(--black-10, rgba(0, 0, 0, 0.10));
  border-top: 1px solid var(--black-10, rgba(0, 0, 0, 0.10));
  background: rgba(233, 67, 89, 0.03);
}

.bl-ai-insight {
  border-radius: 0.25rem;
  border: 1px solid #FAD207;
  background: rgba(250, 210, 7, 0.08);
}

.bl-body-hover:hover {
  background: #fafafa;
}

.bl-source-stage-body {
  height: 100%;
  display: grid;
  grid-template-rows: 40% 60%;
}

.bl-company-source-stage {
  height: 0.4375rem;
  align-self: center;
  min-width: 20%;
}

.bl-prospects {
  background-color: #F6C85F;
  border-color: #F6C85F;
}

.bl-due-diligence {
  background-color: #69DEFE;
  border-color: #69DEFE;
}

.bl-negotiation {
  background-color: #0B84A5;
  border-color: #0B84A5;
}

.bl-closed {
  background-color: #DE89FF;
  border-color: #DE89FF;
}

.bl-default-status {
  background-color: #F1F1F1;
  border-color: #F1F1F1;
}

.bl-h-10px {
  height: 0.7rem !important;
}

.tooltip-inner {
  font-size: x-small !important;
}

.bl-text-underline-hover:hover {
  text-decoration: underline;
  color: blue;
}

.bl-h-3rem {
  height: 3rem;
}

.bl-app-pinned-pos {
  position: absolute;
  top: 10px;
  right: 10px;
}

.left-100 {
  left: 100% !important;
}

.bl-recommended-app-icon {
  width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
}

.bl-w-9rem {
  width: 9rem;
}

.bl-rule-box1 {
  border-bottom: 3px solid #FF403B;
}

.bl-rule-box2 {
  border-bottom: 3px solid #FFBA39;
}

.bl-rule-box3 {
  border-bottom: 3px solid #8AD129;
}

.bl-task-category {
  height: 2rem;
  width: 2rem;
}

.tooltip {
  z-index: 9 !important;
}

.bl-h-4rem {
  height: 4rem;
}

.bl-w-fill-available {
  width: -webkit-fill-available;
}

.bl-limited-text {
  white-space: nowrap;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bl-like-dislike-icon {
  padding: 2px 4px;
  background: #80808091;
  border-radius: 50%;
  margin-left: 0.5rem;
}

.bl-bg-lightpink {
  background: #fdebee !important;
}

.bl-selected-source {
  border-radius: 3px;
  backdrop-filter: blur(8px);
  border: 0.5px solid #80808029;
}

.bl-truncate-line-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.bl-informative-cards {
  background: #DDE7F1;
}

.bl-h-75rem {
  height: 0.75rem !important;
}

.bl-empty-icon {
  background: url(./../assets/img/empty-widget.png), lightgray 50% / cover no-repeat;
}

.card.shadow-sm .card-header {
  background-color: #00000008 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.bl-profile-highlights p,
.bl-profile-highlights a {
  font-size: 13px;
}

.bl-finance-graph thead th {
  white-space: nowrap;
}

.bl-articles-card .card {
  max-width: 23.5% !important;
  min-width: 23.5% !important;
  margin-bottom: 1rem;
}

.bl-w-50 .card {
  max-width: 50% !important;
  min-width: 50% !important;
}

.bl-configure-btn::after {
  display: inline-block !important;
}

.bl-source-badge {
  color: rgba(33, 33, 33, 0.60);
  font-size: 0.6375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem;
  background-color: transparent;
  display: inline-flex;
  padding: 0.0625rem 0.4375rem;
  align-items: center;
  gap: 0.125rem;
  border-radius: 0.5rem;
  border: 0.5px solid rgba(0, 0, 0, 0.20);
  height: 1rem;
  align-self: center;
}

.bl-h-9rem {
  height: 9rem;
  ;
}

.bl-source-overview-list:hover .bl-hover-show {
  display: block !important;
}

/* .bl-source-overview-list td:nth-child(2){
  min-height: 8rem;
} */

.bl-hover-show {
  display: none;
  top: 5px;
  right: 10px;
  width: 'auto';
  cursor: pointer;
}

.bl-fixed-right-panel {
  position: fixed;
  right: 0;
  width: 17rem;
  overflow-y: auto;
  height: 93vh;
}

.bl-menu-accordion {
  padding: 0.5rem 0.2rem 0rem 0.8rem !important;
  color: #ffffff8c !important;
}

.bl-bg-black {
  background: #000 !important;
}

.bl-menu-accordion .nav-item.selected button {
  background: #434141 !important;
}

.nav-item.selected .bl-menu-accordion .nav-item button {
  color: #ffffff8c !important;
}

.nav-item.selected .bl-menu-accordion .nav-item.selected button {
  color: #ffffff !important;
}

.bl-pr-point3 {
  padding-right: 0.3rem !important;
}

.bl-section-header {
  position: absolute;
  margin-top: -0.8rem;
  margin-left: 0.5rem;
  background: white;
  display: inline-flex;
  padding: 0rem 0.625rem;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem !important;
}

.bl-profile-circle {
  border-radius: 50% !important;
  padding: 6px;
  color: #253a6b;
  font-weight: 500;
  font-size: 10px;
  border: 1px solid;
  max-height: 1.8rem;
}

.bl-memo-context section h6 {
  font-size: 80%;
}

.bl-h-9rem {
  height: 9rem;
}

.bl-max-w-90 {
  max-width: 90%;
}

/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline {
  white-space: nowrap;
  overflow-x: hidden;
  text-align: center;
}

.timeline ol {
  font-size: 0;
  /* width: 100vw; */
  padding: 200px 0;
  transition: all 1s;
}

.timeline ol li {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 13%;
  height: 3px;
  background: #e9ecef;
}

.timeline ol li:last-child {
  width: 280px;
}

.timeline ol li:not(:first-child) {
  margin-left: 14px;
}

.timeline ol li:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  bottom: 0;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #FFAF46;
}

.timeline ol li div {
  position: absolute;
  left: calc(100% + -54%);
  width: 280px;
  padding: 15px;
  font-size: 0.9rem;
  white-space: normal;
  color: black;
}

/* .timeline ol li div::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
} */

.timeline ol li:nth-child(odd) div {
  top: -16px;
  transform: translateY(-100%);
}

.timeline ol li:nth-child(odd) div::before {
  top: 100%;
  border-width: 8px 8px 0 0;
  border-color: white transparent transparent transparent;
}

.timeline ol li:nth-child(even) div {
  top: calc(100% + 16px);
}

.timeline ol li:nth-child(even) div::before {
  top: -8px;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent white;
}

.timeline time {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: gray
}


/* TIMELINE ARROWS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline .arrows {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.timeline .arrows .arrow__prev {
  margin-right: 20px;
}

.bl-border-none {
  border: none !important;
}

.bl-iframe-h {
  height: calc(100vh - 3.8rem) !important;
}

.bl-deal-consideration-sec .progress {
  height: 0.40rem !important;
}

.bl-deal-badge {
  border: 1px solid #e74a3bb8 !important;
  color: #e74a3bb8;
  padding: 0.2rem 1rem;
  border-radius: 2rem;
  margin-left: 2px;
}

.bl-modal-nav-sec {
  background: #F0F0F0;
}

.bl-modal-active-nav {
  background: #CCCCCC;
}

.bl-min-h-9rem {
  min-height: 9rem;
}

.bl-text-pink {
  color: #FFC0CB !important;
}

.bl-bg-pink {
  background-color: #FFC0CB !important;
}

.bl-table-cell-width {
  width: 8.5rem;
  ;
}

.bl-heatmap-right-panel-text {
  font-size: x-large !important;
}

.bl-heatmap-right-panel-bg {
  background: #fafafa84;
}

/* --------------- */

.bl-legend-outline {
  border-color: #d3d1d1 !important;
}

.bl-heatmap-legend_notStarted {
  background: #fdfebf3d;
}

.bl-heatmap-legend_pending {
  background: #fbe08b;
}

.bl-heatmap-legend_completed {
  background: #a5d96aa4;
}

.bl-heatmap-legend_overdue {
  background: #f46c43c1;
}

/* --------------- */

.bl-heatmap-legend_notStarted_text {
  color: #fdfebf !important;
}

.bl-heatmap-legend_pending_text {
  color: #fbe08b;
}

.bl-heatmap-legend_completed_text {
  color: #a6d96a;
}

.bl-heatmap-legend_overdue_text {
  color: #f46d43;
}


.bl-h-20rem {
  height: 20rem !important;
}

.bl-db-fileupload-border {
  border: 1px dashed #939393;
}

.bl-db-aifilter-textbox {
  width: 35rem !important;
}

.bl-db-fileupload-animateText {
  /* white-space: nowrap;
  overflow: hidden;
  width: 100%; */
  animation: animtext 2s steps(80, end);
  transition: all cubic-bezier(0.1, 0.7, 1.0, 0.1);
}

@keyframes animtext {
  from {
    width: 0;
    transition: all 2s ease-in-out;
  }
}

.bl-listLogo {
  width: 4rem !important;
}

.bl-animated-text-item {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in-out forwards;
}

.bl-animated-text-item:nth-child(1) {
  animation-delay: 0s;
}

.bl-animated-text-item:nth-child(2) {
  animation-delay: 0.2s;
}

.bl-animated-text-item:nth-child(3) {
  animation-delay: 0.4s;
}

.bl-animated-text-item:nth-child(4) {
  animation-delay: 0.6s;
}

.bl-animated-text-item:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bl-active-tabs-style {
  border-bottom: 3px solid #4e73df;
}

.bl-analyze-rightpanel-bg {
  background-color: #f3f3f3;
}

.bl-analyze-rightpanel-colLink {
  text-decoration: none !important;
}

.bl-analyze-editableTitle {
  font-size: 24px !important;
  width: 12rem;
  height: 40px;
  color: #000;
}

.ssd {
  position: relative;
}

.ssd .bl-ai-filter-div {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
}

.bl-h-fit-content {
  height: fit-content !important;
}

.ssd b {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
}

.bl-db-aifilter-textbox2 {
  width: 22rem !important;
}

.bl-db-aiInsight-btn {
  height: 27px;
}

.bl-w-58rem {
  width: 58rem !important;
}

#canvasConfigTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
}

#canvasConfigTable th,
#canvasConfigTable td {
  /* border: 1px solid #ccc; */
  padding: 8px;
  min-width: 120px;
  text-align: left;
}

/* Make cells editable with a light background */
#canvasConfigTable th[contenteditable="true"],
#canvasConfigTable td[contenteditable="true"] {
  /* background-color: #fafafa; */
  outline: none;
}

.btn-circle-sm {
  height: 1rem !important;
  width: 1rem !important;
  font-size: 0.5rem !important;
}

.highlight {
  border: 2px solid red !important;
}

.suggestions {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  display: none;
  width: auto;
}

.suggestions div {
  padding: 8px;
  cursor: pointer;
}

.suggestions div:hover {
  background-color: #f0f0f0;
}

.bl-canvas-cell-highlight-bg{
  background-color: #03a8f41d;
}

/* ---------  new loginPage styles ------------- */


.bg-image {
  background: url('https://i.pinimg.com/originals/54/58/a1/5458a14ae4c8f07055b7441ff0f234cf.gif');
  filter: blur(50px);
  -webkit-filter: blur(50px);
  height: 100vh; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Position text in the middle of the page/image */
.bg-text-cover {
  background-color: #fff; /* Fallback color */
  background-color: #ffffff5b;
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 2;
  width: 100%;
}
.bg-text { 
  text-align: center;
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, 0.069);
  min-height: 100vh;
  position: relative;
}
.bg-text header{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
.bg-text footer{
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.bg-text footer li:hover{
  text-decoration: underline;
}
.bg-text-white{
  color:#ffffff !important;
}
.bg-login-form-container{
    width: 20%;
    margin:auto;
}

.vista_login_formElements_top {
  border:0px solid #fff !important;
  font-size: .8rem !important;
  padding: 1.5rem 1rem !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important; 
  border-top-right-radius: 8px !important; 
  border-top-left-radius: 8px !important; 
}
.vista_login_formElements_bottom {
  border:0px solid #fff !important;
  font-size: .8rem !important;
  padding: 1.5rem 1rem !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important; 
  border-top-right-radius: 0px !important; 
  border-top-left-radius: 0px !important; 
  margin-top:-15px;
}