@import url('./css/bootstrap-styles.css');
@import url('./css/bl-custom-styles.css');
@import url('./js/font-awesome-4.7.0/css/font-awesome.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mainmenu button,
.sub-menu button {
  border: 0;
  background-color: transparent;
}

.mainmenu button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mainmenu .nav-item.active .nav-link {
  font-weight: 500 !important;
}

.sidebar button:focus {
  outline: none !important;
}

.sub-menu button {
  color: #fff !important;
}

.btn-navigate {
  border: 0;
  background-color: transparent;
}

.btn-navigate:focus {
  outline: none !important;
}

.sidebar button.active {
  background-color: #d3d3d3;
}

.dropdown-menu button {
  background-color: transparent;
  color: #6c6d74;
}

.dropdown-menu button:focus {
  outline: none !important;
}

.dropdown-menu button:active {
  color: #6c6d74 !important;
  background-color: transparent !important;
}

.navbar button {
  border: 0;
  background-color: transparent !important;
}

.collapsed-header-margin-sub-menu {
  left: 289px !important;
  transition: all 0.4s linear !important;
}

.collapsed-content-margin-sub-menu {
  margin-left: 289px !important;
  transition: all 0.4s linear !important;
}

.mob-collapsed-header-margin-sub-menu {
  left: 100px !important;
  transition: all 0.4s linear !important;
}

.mob-collapsed-content-margin-sub-menu {
  margin-left: 100px !important;
  transition: all 0.4s linear !important;
}

em {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  /* Apply italic style if needed */
}

.cp {
  cursor: pointer;
}

.mainmenu {
  z-index: 9;
}

.mainmenu .nav-link {
  padding: 0 0.5rem 0.8rem !important;
  margin-bottom:0.4rem; 
}

.mainmenu .sidebar-divider {
  margin: 0 1rem 0.8rem !important;
}

.mainmenu button {
  color: #23232380 !important;
}

.mainmenu .nav-item.active .active-menu {
  background: #232323d4;
  color: #fafafa !important;
  padding: 0.4rem;
  /* margin: 0.25rem; */
  border-radius: 2rem;
  width: 3rem;
}

.mainmenu .nav-item.active span {
  color: #232323;
}

.sub-menu {
  overflow-y: auto;
  height: 100vh;
  overflow-x: hidden;
  z-index: 8;
}

.sub-menu .nav-item .nav-link {
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  margin-left: 0.3rem;
  color: #ffffff8c !important;
}

.sub-menu .nav-item.selected button {
  /* border-left: 4px solid #fafafa; */
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  color: #ffffff !important;
}

.bg-gray {
  background: #FAFAFA;
}

li a {
  cursor: pointer;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.mobile .mob-hide {
  display: none;
}

.mobile .dash-title {
  overflow: auto;
}

.dash-title h4 {
  white-space: nowrap;
}


/* Button Behaviours change */

.btn-primary {
  color: #670411 !important;
  background-color: #FDEBEE !important;
  border: 1px solid #E94359 !important;
}

.btn-primary:hover {
  background-color: #e943596b !important;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #FDEBEE !important;
}

.btn-filter {
  background: #EE7B8B !important;
  border-color: #E94359 !important;
  color: #ffffff !important;
}

.btn-filter:hover {
  background: #f08594 !important;
  color: #ffffff !important;
}

.btn-filter:focus {
  box-shadow: 0 0 0 0.2rem #f08594 !important;
}

.filter-btn button {
  line-height: 0.5;
  padding: 0.65rem;
  margin-top: 12px;
}

.clear-btn {
  margin-top: 10px;
  font-weight: 600;
}

.font-small {
  font-size: 90%;
}

.font-smaller {
  font-size: 80%;
}


/* p-TreeTable */
.p-treetable {
  font-family: 'Open Sans', sans-serif !important;
  min-width: 20rem;
}

.p-treetable .p-treetable-thead>tr>th {
  padding: 0.5rem !important;
}

.p-treetable .p-treetable-tbody>tr>td {
  padding: 0.5rem !important;
}

.p-treetable-table {
  font-size: 0.75rem !important;
}

.p-checkbox {
  display: grid;
  place-items: center;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid !important;
  border-radius: 2px !important;
  height: 13px !important;
  width: 13px !important;
}

.p-inputtext {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid !important;
  border-radius: 3px !important;
  font-size: 12px !important;
  padding: 0.3rem !important;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  height: 10px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.p-inputtext {
  width: 100% !important;
}

.p-treetable .p-treetable-thead>tr>th {
  background: transparent !important;
  border-width: 0 0 2px 0 !important;
}

.p-treetable .p-treetable-tbody tr:hover {
  background: #fafafa;
}



.p-datatable {
  font-family: 'Open Sans', sans-serif !important;
  min-width: 20rem;
}

.p-datatable .p-datatable-thead>tr>th {
  padding: 0.5rem !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 0.5rem !important;
}

.p-datatable-table {
  font-size: 0.75rem !important;
}

.p-datatable .p-datatable-thead>tr>th {
  background: transparent !important;
  border-width: 0 0 2px 0 !important;
}

.p-datatable .p-datatable-tbody tr:hover {
  background: #fafafa;
}

.p-datatable .p-datatable-tfoot>tr>td {
  background: #fff !important;
  padding: 0.5rem !important;
}

.p-datatable .p-datatable-tbody>tr:last-child>td {
  border: none !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  height: 10px !important;
}

.bl-card-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -24px 0 #FF3D00, 24px 0 #FFF;
  }

  66% {
    background: #FF3D00;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }

  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FF3D00;
  }
}

.page-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99999;
  /* Set a high z-index to ensure it's on top of other content */
  display: none;
  /* Initially hidden */
}

.page-loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.page-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99999;
  /* Set a high z-index to ensure it's on top of other content */
  display: none;
  /* Initially hidden */
}

.page-loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.spin-loader {
  width: 35px;
  height: 35px;
  border: 5px solid #c4c2c2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.line-loader,
.line-loader:before,
.line-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.line-loader {
  display: flex;
  color: #c4c2c2;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  top: -12px;
  margin-left: auto;
  margin-right: auto;
}

.line-loader:before,
.line-loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.line-loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.line-loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em
  }

  40% {
    box-shadow: 0 2.5em 0 0
  }
}